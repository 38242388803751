<template>
  <div class="author_worker">
    <div v-if="books_list&&books_list.length>0">
      <div v-for="item in books_list" :key="item.id" class="rank_show_item">
        <showItem :options="item"></showItem>
      </div>
      <div class="author_worker_page">
        <cPage :page="page" :callBack="loadData"></cPage>
      </div>
    </div>
    <div v-else>
      <NoneData></NoneData>
    </div>
  </div>
</template>
<script>
import showItem from "@/views/ranking/components/rank-show-item.vue";
import { author_of_books } from "@/https/author_center.js";
export default {
  components: {
    showItem,
  },
  data() {
    return {
      showItemArr: [
        {
          id: 1,
          img_src: require("../../../assets/images/book_bc.jpg"),
          book_name: "作品名称",
          worker: "作者",
          classify: "作品分类",
          status: "状态",
          book_info:
            "作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介",
          words: "20万",
          is_show: false,
        },
      ],
      page: {
        page: 1,
        limit: 10,
        total: 0,
      },
      books_list: [],
    };
  },
  mounted() {
    
  },
  created(){
    this.avoid_list();
  },
  methods: {
    loadData() {
      this.avoid_list();
    },
    avoid_list() {
      let params = {
        ...this.page,
        id: Number(this.$route.query.id),
      };
      author_of_books(params).then((res) => {
        if (res.code === 0) {
          this.books_list = res.data;
          this.page.total = res.total;
        } else {
          this.message.warning(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.author_worker {
  width: 100%;
  // height: 500px;
  // border: 1px solid #e4e4e4;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 0 30px;
  // box-shadow: 0 0 5px #e4e4e4;
  .rank_show_item {
    display: inline-block;
    margin: auto 120px;
    padding: 50px 30px;
    width: calc(100% - 240px);
    height: 200px;
    border: none;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #eeeeee;
    > div {
      margin: 10px 0;
    }
  }
  .rank_item_left > .rank_item_index {
    display: none;
  }
  .author_worker_page {
    margin: 80px 150px;
  }
}
</style>