<template>
  <div class="author_info">
    <div class="author_info_top">
      <topHeader></topHeader>
    </div>
    <div class="container author_info_bottom">
      <authorIntro></authorIntro>
      <div class="author_list_title">作品列表</div>
      <workList></workList>
    </div>
  </div>
</template>
<script>
import topHeader from "@/components/main/top-header";
import authorIntro from "./componets/author_intro";
import workList from "./componets/worke_list";
export default {
  components: {
    topHeader,
    authorIntro,
    workList,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.author_info {
  background-color: #fafcff;
  min-height: 100vh;
  .author_info_top {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-shadow: 0 5px 5px -5px rgba(153, 153, 153, 0.2);
  }
  .author_info_bottom {
    padding-top: 25px;
    .author_list_title {
      width: 90px;
      margin: 20px;
      text-align: center;
      padding: 10px 0;
      font-size: 18px;
    //   color: rgb(79, 156, 251);
      border-bottom: 2px solid rgba(153, 153, 153, 0.2);
    //   cursor: pointer;
    }
  }
}
</style>