<template>
  <div
    class="author_intro"
    flex
  >
    <img
      :src="author_info.image"
      v-if="author_info.image"
    >
    <img
      src="../../../assets/images/default_header.png"
      v-else
    >
    <div class="author_intro_center">
      <p>{{author_info.author_name}}</p>
      <p class="data_work">
        <span class="time_long">创作时长
          <span>{{author_info.author_time||0}}</span>
        </span>
        <span>作品总数
          <span>{{author_info.book_count||0}}</span>
        </span>
        <span>
          <span>{{author_info.like_count||0}}</span>
          关注者
        </span>
      </p>
    </div>
    <div
      class="author_intro_right"
      flex
      between
      align
    >
      <div class="like_it">
        <i
          class="iconfont icon-my-guanzhu3"
          :class="author_info.is_follow===1?'guanzhu ':''"
        ></i>
        <p
          v-text="author_info.is_follow===0 ? '关注':'已关注'"
          @click="favior"
        ></p>
      </div>

      <div
        style="width:140px"
        v-if="open_relation()"
      >
        <p
          v-show="is_author"
          style="width:100%"
          @click.once="learn"
          words-wrap
        >
          <i
            class="iconfont"
            :class="[
                author_info.is_master===0||author_info.is_master===-1?'icon-xinfeng1':'',
                author_info.is_master===1?'icon-laoshi':'',
                author_info.is_master===2?'icon-yaoqinghan2':'',
                ]"
          >
          </i>
          <span>{{ {'0':'拜师','1':'已拜师','2':'已发送拜师函','-1':'拜师'}[author_info.is_master] }}</span>
        </p>
      </div>

    </div>
  </div>

</template>
<script>
import { author_info, apply_master, author_apply, user_follow } from "@/https/author_center.js"
export default {
  data () {
    return {
      img: require("../../../assets/images/hear_01.jpg"),
      favior_flag: false,
      learn_flag: 1,
      is_author: true,
      author_info: "",
    };
  },
  mounted () {
    this.avoid_info();
  },

  methods: {
    favior () {
      let params = {
        follow_user_id: Number(this.$route.query.id),
        type: this.author_info.is_follow === 1 ? 2 : 1
      }
      user_follow(params).then(res => {
        if (res.code === 0) {
          this.avoid_info()
        } else if (res.code === 1002) {
          this.$store.commit("set_login_type", true)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    learn () {
      let params = {
        master_id: Number(this.$route.query.id)

      }
      apply_master(params).then(res => {
        if (res.code === 0) {
          this.avoid_info()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },

    avoid_info () {
      let params = {
        id: Number(this.$route.query.id),
        // id:2
      };
      author_info(params).then((res) => {
        if (res.code === 0) {
          this.author_info = res.data;
        } else {
          this.$message.warning(res.msg);
        }
      });
    }
  }
}

</script>
<style lang="scss" scoped>
@import '../../../utils/tool.scss';
.author_intro {
  width: 100%;
  height: 180px;
  // border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 18px;
  background-color: #fff;
  box-shadow: 0 0 5px #e4e4e4;
  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
  }

  .author_intro_center {
    margin-left: 40px;
    padding: 40px 0 10px 0;
    & p:nth-of-type(1) {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 45px;
    }
    // & p:nth-of-type(2) {
    //   > span {
    //     font-size: 12px;
    //     padding: 10px 40px;
    //     span {
    //       font-size: 18px;
    //     }
    //   }
    //   & span:nth-of-type(1) {
    //     padding-left: 0;
    //   }
    //   & span:nth-of-type(2) {
    //     border-left: 1px solid rgba(228, 228, 228, 1);
    //     border-right: 1px solid rgba(228, 228, 228, 1);
    //   }
    // }
    .data_work {
      @include flex-row();
      align-items: center;
      > span {
        font-size: 12px;
        padding: 10px 20px;
        border-left: 1px solid rgba(228, 228, 228, 1);
        span {
          font-size: 18px;
          padding-left: 5px;
        }
      }
      .time_long {
        border-left: 0px solid rgba(228, 228, 228, 1);
      }
    }
  }
  .author_intro_right {
    width: calc(100% - 788px);
    margin-left: 200px;
    padding: 70px 20px 30px 50px;
    p {
      cursor: pointer;
      // width: 40%;
    }
    p:hover {
      color: rgb(79, 156, 251);
    }
  }
}
.like_it {
  width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.guanzhu {
  color: rgb(247, 81, 99) !important;
}
.iconfont {
  color: #cacaca;
  font-size: 24px;
  padding-right: 5px;
}
.icon-yaoqinghan2 {
  color: rgb(247, 81, 99) !important;
}
.icon-laoshi {
  color: rgb(173, 129, 245) !important;
}
</style>
