import {request} from "./config"

let NewUrl = ''

//作家注册
export const author_apply = (params) => request({
  method: 'post',
  url:  NewUrl + 'author/apply',
  data: params
})

//作家详情
export const author_info = (params) => request({
  method: 'get',
  url:  NewUrl + 'author/info',
  data: params
})

//作家作品列表
export const author_of_books = (params) => request({
  method: 'get',
  url:  NewUrl + 'author/of_books',
  data: params
})


//拜师
export const apply_master = (params) => request({
  method: 'post',
  url:  NewUrl + 'web/user/apply_master',
  data: params
})

//拜师
export const user_follow = (params) => request({
  method: 'post',
  url:  NewUrl + 'web/user/follow',
  data: params
})

